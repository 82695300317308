import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import { SchoolService } from '../school.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {minSelectedCheckboxesValidator} from "./min-selected-checkboxes.validator";

@Component({
  templateUrl: './exit-survey.component.html',
  styleUrls: ['./exit-survey.component.scss'],
})
export class ExitSurveyComponent implements OnInit {
  edinaOrgId = '';
  subscriptionId = '';
  showSuccessMessage: boolean;
  getDataError: boolean;
  showForm: boolean;
  showSubmitError = false;

  public cancellationReasonsFormGroup: FormGroup = new FormGroup({
    inefficient: new FormControl(false),
    tooComplex: new FormControl(false),
    expensive: new FormControl(false),
    dontUse: new FormControl(false),
    unreliable: new FormControl(false),
    noBudget: new FormControl(false),
    cheaperAlternative: new FormControl(false),
    cheaperAlternativeDetails: new FormControl(''),
    freeAlternative: new FormControl(false),
    freeAlternativeDetails: new FormControl(''),
    lakeResources: new FormControl(false),
    otherCancellationReason: new FormControl(false),
  }, minSelectedCheckboxesValidator(1));

  public usageReasonFormGroup: FormGroup = new FormGroup({
    lackDevices: new FormControl(false),
    notUseful: new FormControl(false),
    lakeTraining: new FormControl(false),
    missingFeatures: new FormControl(false),
    missingFeaturesDetails: new FormControl(''),
    moreActivities: new FormControl(false),
    otherUsageReason: new FormControl(false),
  });

  public formGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    cancellationReasons: this.cancellationReasonsFormGroup,
    otherCancellationReasonDetails: new FormControl(''),
    usageReasons: this.usageReasonFormGroup,
    usageOther: new FormControl(''),
    anyOtherComments: new FormControl(''),
  });

  constructor(private readonly route: ActivatedRoute, private schoolService: SchoolService) {}

  ngOnInit() {
    this.route.params
      .pipe(
        mergeMap((params: any) => {
          this.edinaOrgId = params['edinaOrgId'];
          this.subscriptionId = params['subscriptionId'];
          return this.schoolService.getSurveyInfo(this.edinaOrgId, this.subscriptionId);
        })
      )
      .subscribe({
        next: data => {
          this.showForm = true;
          this.getDataError = false;
          this.formGroup.controls['title'].setValue(data.title);
          this.formGroup.controls['firstName'].setValue(data.firstName);
          this.formGroup.controls['lastName'].setValue(data.lastName);
          this.formGroup.controls['email'].setValue(data.email);
        },
        error: () => {
          this.showForm = false;
          this.getDataError = true;
        },
      });
  }

  clearOtherCancellationReasonDetails() {
    this.formGroup.get('otherCancellationReasonDetails')?.setValue('');
  }

  clearUsageOther() {
    this.formGroup.get('usageOther')?.setValue('');
  }

  clearAnyOtherComments() {
    this.formGroup.get('anyOtherComments')?.setValue('');
  }

  handleSubmit() {
    this.schoolService.sendSurvey(this.formGroup.value, this.edinaOrgId, this.subscriptionId).subscribe({
      next: () => {
        this.showSuccessMessage = true;
        this.showForm = false;
      },
      error: () => {
        this.showSubmitError = true;
      },
    });
  }
}
