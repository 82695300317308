<h1>Exit Survey</h1>
<div *ngIf="!showForm && !getDataError && !showSuccessMessage">
  <mat-spinner></mat-spinner>
</div>
<mat-error *ngIf="!showForm && !showSuccessMessage"> You don't have permissions to fill the survey!</mat-error>
<form [formGroup]="formGroup" (ngSubmit)="handleSubmit()" *ngIf="showForm && !showSuccessMessage">
  <p>
    We are sorry that you have decided not to continue with Digimap for Schools.
    In order to improve the service we would be very grateful if you could complete the following two questions:
  </p>
  <mat-form-field appearance="legacy">
    <input matInput placeholder="Title" formControlName="title" required />
  </mat-form-field>
  <mat-form-field appearance="legacy">
    <input matInput placeholder="Name" formControlName="firstName" required />
  </mat-form-field>
  <mat-form-field appearance="legacy">
    <input matInput placeholder="Surname" formControlName="lastName" required />
  </mat-form-field>
  <mat-form-field appearance="legacy">
    <input matInput type="email" placeholder="Enter your email" formControlName="email" required />
  </mat-form-field>

  <div class="form-group" formGroupName="cancellationReasons">
    <label id="cancelling-reason-label">
      What are the reasons for not renewing your subscription? Please select all that apply.
    </label>
    <ul>
      <li>
        <mat-checkbox color="primary"
                formControlName="inefficient"
                aria-labelledby="cancelling-reason-label">
          doesn't do what we wanted it to do
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="tooComplex"
                      aria-labelledby="cancelling-reason-label">
          lack of understanding how to use the service
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="expensive"
                      aria-labelledby="cancelling-reason-label">
          too expensive
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="dontUse"
                      aria-labelledby="cancelling-reason-label">
          level of use doesn't justify the subscription
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="unreliable"
                      aria-labelledby="cancelling-reason-label">
          service was too slow/unreliable to be useful
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="noBudget"
                      aria-labelledby="cancelling-reason-label">
          no available budget
        </mat-checkbox>
      </li>
      <li class="alt-service-options">
        <mat-checkbox color="primary"
                      formControlName="cheaperAlternative"
                      aria-labelledby="cancelling-reason-label">
          found another preferable paid-for service (please specify below)
        </mat-checkbox>
        <mat-form-field *ngIf="cancellationReasonsFormGroup.value.cheaperAlternative"
                        appearance="outline">
          <mat-label>Preferable paid-for service</mat-label>
          <input matInput
                 placeholder="Please specify the preferable paid-for service"
                 formControlName="cheaperAlternativeDetails"/>
        </mat-form-field>
      </li>
      <li class="alt-service-options">
        <mat-checkbox color="primary"
                      formControlName="freeAlternative"
                      aria-labelledby="cancelling-reason-label">
          found another preferable free service (please specify below)
        </mat-checkbox>
        <mat-form-field *ngIf="cancellationReasonsFormGroup.value.freeAlternative"
                        appearance="outline">
          <mat-label>Preferable free service</mat-label>
          <input matInput
                 placeholder="Please specify the preferable free service"
                 formControlName="freeAlternativeDetails"/>
        </mat-form-field>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="lakeResources"
                      aria-labelledby="cancelling-reason-label">
          not enough learning resources
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="otherCancellationReason"
                      aria-labelledby="cancelling-reason-label">
          other (please specify below.....)
        </mat-checkbox>
      </li>
    </ul>
  </div>
  <mat-form-field *ngIf="cancellationReasonsFormGroup.value.otherCancellationReason"
                  appearance="outline" class="long-input">
    <mat-label>Other reason for cancelling</mat-label>
    <input matInput
           placeholder="Please provide more detail about other reasons for cancelling"
           formControlName="otherCancellationReasonDetails"/>
    <button *ngIf="formGroup.value.otherCancellationReasonDetails"
            matSuffix mat-icon-button aria-label="Clear other reasons for cancelling"
            (click)="clearOtherCancellationReasonDetails()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div *ngIf="cancellationReasonsFormGroup.value.dontUse" formGroupName="usageReasons" class="form-group">
    <label id="low-usage-reason-label">
      If lack of usage was the reason for cancelling please could you tell us why you think usage was low. Please select all that apply:
    </label>
    <ul>
      <li>
        <mat-checkbox color="primary"
                      formControlName="lackDevices"
                      aria-labelledby="low-usage-reason-label">
          lack of available devices for teachers/pupils
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="notUseful"
                      aria-labelledby="low-usage-reason-label">
          service did not do what was expected
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="lakeTraining"
                      aria-labelledby="low-usage-reason-label">
          lack of training in how to use the service
        </mat-checkbox>
      </li>
      <li class="alt-service-options">
        <mat-checkbox color="primary"
                      formControlName="missingFeatures"
                      aria-labelledby="low-usage-reason-label">
          missing features (please give more details below)
        </mat-checkbox>
        <mat-form-field *ngIf="usageReasonFormGroup.value.missingFeatures"
                        appearance="outline">
          <mat-label>Missing features</mat-label>
          <input matInput
                 placeholder="Please specify the missing features"
                 formControlName="missingFeaturesDetails"/>
        </mat-form-field>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="moreActivities"
                      aria-labelledby="low-usage-reason-label">
          needed more ready-made activities for use in class
        </mat-checkbox>
      </li>
      <li>
        <mat-checkbox color="primary"
                      formControlName="otherUsageReason"
                      aria-labelledby="low-usage-reason-label">
          other (please specify.....)
        </mat-checkbox>
      </li>
    </ul>
  </div>
  <mat-form-field *ngIf="usageReasonFormGroup.value.otherUsageReason"
                  appearance="outline" class="long-input">
    <mat-label>Other reason for lack of usage</mat-label>
    <input matInput
           placeholder="Please provide more detail about other reasons for lack of usage"
           formControlName="usageOther" />
    <button *ngIf="formGroup.value.usageOther"
            matSuffix mat-icon-button aria-label="Clear other usage"
            (click)="clearUsageOther()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div class="form-group">
    <mat-form-field appearance="outline" class="long-input">
      <mat-label>Any other comments you wish to make:</mat-label>
      <textarea matInput formControlName="anyOtherComments">
      </textarea>
      <button *ngIf="formGroup.value.anyOtherComments"
              matSuffix mat-icon-button aria-label="Clear other comments"
              (click)="clearAnyOtherComments()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="form-action">
    <mat-hint *ngIf="showForm && !formGroup.valid && !formGroup.value.anyOtherComments"
      class="validation-error-message">
      Please select at least one option above before submitting form.
    </mat-hint>
    <button mat-raised-button color="primary"
            class="button" type="submit"
            [disabled]="!formGroup.valid && !formGroup.value.anyOtherComments">
      Submit
    </button>
    <mat-error *ngIf="showSubmitError">Your submission has failed. Please Try again!</mat-error>
  </div>
</form>
<div *ngIf="showSuccessMessage">Thank you for helping us to improve our service.</div>
